// Если нам надо обработать данные используем мутации
// import { mutations } from './mutations';

export const localActions = {
	recoverPassword(params) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`User/CheckRecoveryCodeAndSetNewPassword?login=${params.login}&code=${params.code}&pass1=${params.pass1}&pass2=${params.pass2}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getCode(login) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`User/SendRecoveryCode?login=${login}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	reSendEmailConfirmedCode(login) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`User/ReSendEmailConfirmedCode?login=${login}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	reSendEmailConfirmed(login) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`User/ReSendEmailConfirmed?login=${login}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	checkEmailConfirmedCode(query) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`User/CheckEmailConfirmedCode?login=${query.login}&code=${query.code}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
