<template>
	<login-form />
</template>

<script>
import loginForm from './ui/loginForm.vue';

export default {
	components: {
		loginForm
	},
	mounted() {
		document.body.classList.add('Auth');
	},
	destroyed() {
		document.body.classList.remove('Auth');
	}
};
</script>
